import {StaticImage} from "gatsby-plugin-image";
import React, {useEffect, useState} from "react";
import Layout from "../../components/layout";
import styled from "@emotion/styled";
import PageTitle from "../../components/common/PageTitle";
import Quote from "../../components/common/Quote";
import BoarderSectionTitle from "../../components/common/BoarderSectionTitle";
import StoreProductImage from "../../components/pages/product/StoreProductImage";
import CoverView from "../../components/common/CoverView";
import philosophyImage from "../../assets/img/texturelab/touch.webp";
import textureImage from "../../assets/img/texturelab/texture.jpg";
import ItemListBlock from "../../components/pages/ItemListBlock";
import CoverView2 from "../../components/common/CoverView2";

export default function Index() {

    const ref = React.createRef<HTMLDivElement>()
    const [isWhite, setIsWhite] = useState<boolean>(true);

    const onScroll = (): void => {
        if (ref!.current!.getBoundingClientRect().top < window.innerHeight/2) {
            setIsWhite(true);
        } else {
            setIsWhite(false);
        }
    };

    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        return (): void => document.removeEventListener("scroll", onScroll);
    });

    return (
        <Layout>
            <Container isWhite={isWhite}>
                <CoverView2 image={
                    <StaticImage
                        src="../../assets/img/texturelab/top.jpg"
                        alt="texturelab top"
                        objectFit="cover"
                        layout="fullWidth"
                        quality={100}
                        className="top-cover"
                    />
                }>
                    <StaticImage
                        src={"../../assets/img/texturelab/keyword.png"}
                        alt="所作と佇まいを美しく"
                        className="cover-title"
                        placeholder="tracedSVG"
                    />
                </CoverView2>

                <div className="page-title">
                    <PageTitle title="Texture Lab"/>
                </div>

                <div className="bl-quote">
                    <Quote cite="傳田光博 ‘第三の脳’ より">
                        皮膚には驚くほどの役割があり、<br/>‘第三の脳’と言われています。
                    </Quote>
                </div>

                <CoverView background={philosophyImage} sp_slide="75%">
                    <div className="bl-sense-wrap">
                        <span className="sense-title-first">「触感」</span>
                        <p className="bl-philosophy-text">
                            身体と環境を繋ぐインターフェイスや自我形成の役割をもち、<br className="hideSP"/>
                            心地の良い皮膚感覚への刺激が豊かな感情を作るなどの心身へと深く影響している。<br className="hideSP"/>
                            先人によって心理学や生理学など様々な分野で研究が行われてきたが、<br className="hideSP"/>
                            まだまだ未知の感覚でもある。<br/>
                            ただ、生物進化初期から備わっていることは解明されており、<br className="hideSP"/>
                            感情や愛情の喚起が人間という存在をより明確なものにしてきたのかもしれない。<br/>
                            今一度、肉体の根源的な感覚に目を向け、<br className="hideSP"/>
                            身体の表面から‘自分‘という存在の価値を知ることはできないだろうか。<br/>
                            <br/>
                            Texuter Labでは、肌に触れる時の‘触感’という視点から、服づくりしています。
                        </p>
                    </div>
                </CoverView>

                <div className="bl-images">
                    <div className="grid-1">
                        <StaticImage
                            src="../../assets/img/photo/2021051212933.jpg"
                            alt="texture lab"
                            className="grid-image"
                            quality={100}
                        />
                    </div>
                    <div className="grid-2">
                        <StaticImage
                            src="../../assets/img/photo/2021051214038.jpg"
                            alt="texture lab"
                            className="grid-image"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                        <StaticImage
                            src="../../assets/img/photo/2021051214045.jpg"
                            alt="texture lab"
                            className="grid-image"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                    </div>
                    <div className="grid-1">
                        <StaticImage
                            src="../../assets/img/photo/2021051213019.jpg"
                            alt="texture lab"
                            className="grid-image"
                            objectFit="contain"
                            quality={100}
                        />
                    </div>
                </div>
                <CoverView background={textureImage}>
                    <div className="slide-left-bottom">
                        <div className="bl-texture-wrap">
                            <span className="sense-title-first">「雅やかに」</span>
                            <p className="bl-philosophy-text">
                                水の流れに身をおくような、浸透するような感覚を繊維一本一本が作り出す。<br/>
                                <br className="hideSP"/>
                                布の持つ肌触りを最大限に堪能できるように<br className="hideSP"/>
                                厳選された素材と立体裁断を駆使し贅沢な布使いのドレーピング<br className="hideSP"/><span className="hidePC">。</span>
                                自社で一点一点丁寧に編み上げる上質なニット生地<br className="hideSP"/><span className="hidePC">。</span>
                                量産では到達できない究極のクチュールニット<br className="hideSP"/><span className="hidePC">。</span>
                                <br/>
                                目を瞑って感じる美しさと、<br/>
                                目を開いて感じる美しさを<br/>
                                雅やかに表現します。
                            </p>
                        </div>
                    </div>
                </CoverView>

                <div className="bl-images">
                    <div className="grid-2">
                        <StaticImage
                            src="../../assets/img/texturelab/tex_photo0.jpg"
                            alt="texture lab"
                            className="grid-image"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                        <StaticImage
                            src="../../assets/img/texturelab/tex_photo1.jpg"
                            alt="texture lab"
                            className="grid-image"
                            objectFit="cover"
                            objectPosition="top"
                            quality={100}
                        />
                    </div>

                    <div className="grid-2 slide_up">
                        <StaticImage
                            src="../../assets/img/texturelab/tex_photo2.jpg"
                            alt="texture lab"
                            className="grid-image"
                            objectFit="contain"
                            objectPosition="top"
                            quality={100}
                        />
                    </div>
                </div>

                {/*<Link to="/texture lab/gallery" className="gallery-link">*/}
                {/*    <StaticImage*/}
                {/*        src="../../assets/img/texture lab/gallery.png"*/}
                {/*        alt="texture lab"*/}
                {/*        className="grid-image"*/}
                {/*        objectFit="contain"*/}
                {/*        objectPosition="top"*/}
                {/*    />*/}
                {/*</Link>*/}

                <div className="bl-product-list" ref={ref}>
                    <BoarderSectionTitle title="Product"/>

                    <div className="products">

                        <StoreProductImage
                            name="ロングガウン"
                            price="¥247,500"
                            link_to="https://shop.knitology.net/products/long-cashmere-gawn">
                            <StaticImage
                                src="../../assets/img/item/2021090822800.webp"
                                alt="ロングガウン：カシミヤ75%ブルーフォックス25%"
                                className="item-image"
                                quality={100}
                            />
                        </StoreProductImage>

                        <StoreProductImage
                            name="ミニガウン： カシミヤ100%"
                            price="¥178,200"
                            link_to="https://shop.knitology.net/products/%E3%82%AC%E3%82%A6%E3%83%B3%E3%83%9F%E3%83%8B-%E3%82%AB%E3%82%B7%E3%83%9F%E3%83%A4100-since-2019">
                            <StaticImage
                                src="../../assets/img/item/2021090822803.webp"
                                alt="ミニガウン： カシミヤ100%"
                                className="item-image"
                                quality={100}
                            />
                        </StoreProductImage>

                        <StoreProductImage
                            name="マフラー"
                            price="¥26,290"
                            link_to="https://shop.knitology.net/products/%E3%82%AB%E3%82%B7%E3%83%9F%E3%83%A4-%E3%83%95%E3%82%A1%E3%83%BC%E5%8A%A0%E5%B7%A5-%E3%83%9E%E3%83%95%E3%83%A9%E3%83%BC">
                            <StaticImage
                                src="../../assets/img/item/2021090822818.webp"
                                alt="マフラー"
                                className="item-image"
                                quality={100}
                            />
                        </StoreProductImage>

                    </div>
                    <ItemListBlock/>
                </div>

            </Container>
        </Layout>
    );
}

const Container = styled.div<{ isWhite: boolean }>`

  background-color: ${(props) => props.isWhite ? "#B9B9B9" : "#000"};
  transition: all 600ms 0s ease;
  padding-bottom: 80px;
  
  .page-title {
    margin: 160px auto;
  }


  .bl-sense-wrap,
  .bl-texture-wrap {

    display: flex;
    flex-direction: column;

    .sense-title-first {
      //font-family: YuMincho;
      font-weight: 500;
      font-size: 54px;
      font-size: min(54px, 14vw);
      letter-spacing: 0.08em;
      line-height: 75px;
      text-align: left;
      color: #fff;
    }

    .bl-philosophy-text {
      padding: 30px;
      //font-family: YuMincho;
      font-weight: 500;
      
      font-size: 17px;
      letter-spacing: 0.08em;
      line-height: 42px;
      text-align: left;
      color: #fff;
      
      @media (max-width: 768px) {
        padding: 30px 10px;
        //max-width: calc(100vw - 20px);
        font-size: 4.2vw;
        line-height: 10vw;
      }
    }
  }

  .bl-texture-wrap {
    align-items: flex-start;
    justify-content: start;

    position:absolute;
    bottom:0%;
    left:0%;
    max-width: 500px;
    
    
  }

  .bl-sense-wrap {
    align-items: flex-start;
    justify-content: start;
    padding-bottom: 130px;
    padding-top: 130px;

    min-height: max(100vh, 840px);
    height: fit-content;
  }
  
  .slide-left-bottom {
    position: relative;
    width: 100%;
    min-height: max(100vh, 840px);
    padding-top: 200px;
  }
  
  .bl-images {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 3440px;
    margin: 0 auto;

    .grid-image {
      margin-top: 120px;
      width: 100%;
      break-inside: avoid;

      @media (max-width: 768px) {
        margin-top: 40px;
      }
    }
  }

  .grid-1 {
    .grid-image {

      margin: 120px auto;
      max-width: 1000px;
      break-inside: avoid;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        margin: 40px auto
      }
    }
  }

  .grid-2 {

    width: 100%;
    max-width: 1440px;
    margin: 40px auto;
    padding: 0 40px;

    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);

    > *:nth-of-type(2) {
      margin-top: 46vw;
    }

    &.slide_up {
      margin: max(-500px, -36vw) auto 0;
    }

    @media (max-width: 768px) {
      margin: 0px auto;
      padding: 0 0px;
      grid-gap: 20px;
    }
  }

  .gallery-link {
    margin: 200px auto 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .bl-product-list {
    margin-top: 200px;
  }

  .products {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 100px auto;
    max-width: 1440px;
    width: 100%;
    padding: 0 20px;

    overflow-x: auto; /* 横スクロールの指定 */
    white-space: nowrap; /* 横スクロールの指定 */
    overflow-scrolling: touch; /* スクロールを滑らかにする */

  }

}
`