import styled from "@emotion/styled";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import Quote from "./Quote";

interface CoverViewProps {
    children: React.ReactNode;
    background:string;
    sp_slide:string;
}

CoverView.defaultProps = {
    sp_slide: "50%",
}

export default function CoverView(props: CoverViewProps) {
    return (
        <Container src={props.background} slide={props.sp_slide}>
            {props.children}
        </Container>
    );
}

const Container = styled.div<{src:string, slide:string}>`

  background-image: url(${(props) => props.src});
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  min-height: max(580px, 100vh);
  //min-height: 580px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1ch;

  @media (max-width: 768px) {
    background-position-x: ${(props) => props.slide};
  }
`;
